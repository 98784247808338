import React from "react"
import Header from "../components/header"
import { Helmet } from "react-helmet"

import Style from "../styles/content.module.css"
import ImpeccableWrapsImg from "../images/projects/impeccable-wraps.png"
import ReactWPMImg from "../images/projects/react-wpm.png"
import TigerBoardsImg from "../images/projects/tiger-boards.png"
import ToDoImg from "../images/projects/to-do.png"

export default () => (
  <div>
    <Helmet>
      <title>Maximo Macchi - Projects</title>
    </Helmet>
    <Header />
    <div className={Style.gridContainer}>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>CSV Parser</h1>
        </div>
        <div className={Style.contentLinks}>
          <div>
            <a
              className={Style.projectLink}
              href="https://github.com/maximomacchi/csv-parser"
            >
              Repository
            </a>
          </div>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              CSV Parser where users can upload CSV files to be parsed and
              analyzed. Files are stored locally once uploaded and data
              analytics are shown to user. Built using React and Node.js.
            </li>
            <li>
              Front end React client handles file uploads, fetching data from
              back end API, and showing API results to user
            </li>
            <li>
              Back end Express API stores CSV files locally, parses CSV files,
              and returns data analytics to client
            </li>
          </ul>
        </div>
      </div>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>URL Shortener</h1>
        </div>
        <div className={Style.contentLinks}>
          <div>
            <a
              className={Style.projectLink}
              href="https://github.com/maximomacchi/url-shortener"
            >
              Repository
            </a>
          </div>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              URL shortener where users can input a URL and receive a shortened
              link, taking the user to the original URL inputted. Currently this
              application works when running locally.
            </li>
            <li>
              Front end React client handles user input, fetching data from API,
              and showing shortened URLs to user
            </li>
            <li>
              Back end Express API validates user input, stores shortened URLs
              in SQLite database, and fetches shortened URLs for client upon API
              calls
            </li>
            <li>
              Redirects used for shortened URLs and ID iterators used for
              generating unique shortened URLs in SQLite database
            </li>
          </ul>
        </div>
      </div>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>Impeccable Wraps Site</h1>
          <a className={Style.imgLink} href="https://impeccablewraps.com/">
            <img
              className={Style.projectImg}
              src={ImpeccableWrapsImg}
              alt="Impeccable Wraps Site"
            ></img>
          </a>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>E-Commerce site built for freelance client using Shopify</li>
            <li>
              Responsive design implemented so site works across multiple
              devices and browsers
            </li>
            <li>
              Built custom UI elements using Liquid, JavaScript, HTML, and CSS
            </li>
            <li>
              Square Appointments embed used on site for online booking of
              business services
            </li>
            <li>
              Collaborated with client to define requirements, establish
              timelines, and estimate costs
            </li>
            <li>
              Met with client bi-weekly presenting visual demos along with
              project updates
            </li>
          </ul>
        </div>
      </div>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>React WPM Test</h1>
          <a
            className={Style.imgLink}
            href="https://maximo-react-wpm-test.netlify.com/"
          >
            <img
              className={Style.projectImg}
              src={ReactWPMImg}
              alt="React WPM Test site"
            ></img>
          </a>
        </div>
        <div className={Style.contentLinks}>
          <div>
            <a
              className={Style.projectLink}
              href="https://github.com/maximomacchi/react-wpm-test"
            >
              Repository
            </a>
          </div>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>React app which tests user's WPM typing speed</li>
            <li>
              Words for test fetched from third-party REST API using Fetch API
              and JSON
            </li>
            <li>React Hooks used for tracking state</li>
            <li>
              useEffect() hook used for updating components as state changes or
              whenever components mount / unmount from the virtual DOM
            </li>
            <li>Test checks accuracy of user's typing while handling timers</li>
          </ul>
        </div>
      </div>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>Hydration Station</h1>
        </div>
        <div className={Style.contentLinks}>
          <div>
            <a
              className={Style.projectLink}
              href="https://github.com/comp129-spr19/project2-skamn"
            >
              Repository
            </a>
          </div>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Electron app which tracks water consumption entered in by user
            </li>
            <li>
              Unit tests using Jest written; integrated with Travis CI and
              GitHub for continuous integration
            </li>
            <li>
              Scrum methodology practiced to follow agile development lifecycle
            </li>
            <li>
              Technologies used include Electron, JavaScript, HTML, CSS, and
              Bootstrap
            </li>
            <li>
              Met with customer weekly to receive feedback on demos and clarify
              requirements
            </li>
          </ul>
        </div>
      </div>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>Tiger Boards</h1>
          <a className={Style.imgLink} href="https://tigerboards.netlify.com/">
            <img
              className={Style.projectImg}
              src={TigerBoardsImg}
              alt="Tiger Boards site"
            ></img>
          </a>
        </div>
        <div className={Style.contentLinks}>
          <div>
            <a
              className={Style.projectLink}
              href="https://github.com/sgosal2/tiger-boards-frontend"
            >
              Repository
            </a>
          </div>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Web app which shows space availability for students at University
              of the Pacific
            </li>
            <li>Front-end uses React.js and Material UI</li>
            <li>
              RESTful API built using Flask which connects to PostgreSQL
              database
            </li>
            <li>
              Front-end handles asynchronous data fetching and live UI updates
            </li>
            <li>
              Google Oauth authentication implemented for user account features
            </li>
            <li>
              Administrative access restricts modification of database data
            </li>
          </ul>
        </div>
      </div>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>To Do App</h1>
          <a className={Style.imgLink} href="https://maximo-to-do.netlify.com/">
            <img
              className={Style.projectImg}
              src={ToDoImg}
              alt="To Do app site"
            ></img>
          </a>
        </div>
        <div className={Style.contentLinks}>
          <div>
            <a
              className={Style.projectLink}
              href="https://github.com/maximomacchi/to-do-list"
            >
              Repository
            </a>
          </div>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Yes, I made a To-Do app. Every developer has to make one, right?
            </li>
            <li>Made using vanilla JavaScript</li>
            <li>Add your own to-do items</li>
            <li>Delete items from the list</li>
            <li>
              Add items from a third-party API, in case you don't want to type
              words :/
            </li>
            <li>Search for items within your list</li>
          </ul>
        </div>
      </div>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>Portfolio Site</h1>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>This portfolio site was built completely by myself</li>
            <li>Built using Gatsby, React.js, HTML, and CSS</li>
            <li>All styling and animations use vanilla CSS</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
